import { useState } from "react";
import axios from "axios";
import React from "react";
import Button from "../universal/Button";
import { useNavigate } from "react-router";
import { supabase } from "../../libs/supabase";

function Login() {
  const navigate = useNavigate();
  const [belepesAdatok, setBelepesAdatok] = useState({
    username: "",
    password: "",
  });
  const [loginError, setLoginError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    const { data, error } = await supabase.auth.signInWithPassword({
      email: belepesAdatok.username,
      password: belepesAdatok.password,
    });

    console.log(data);

    if (data?.user) {
      navigate("/valutachange");
    } else {
      console.error(error);
      setLoginError(true);
    }

    /* try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_IP}/bejelentkezes`,
        belepesAdatok
      );

      // Store JWT token in local storage
      localStorage.setItem("token", response.data.token);

      // Navigate to /valutachange endpoint with Authorization header
      navigate("/valutachange", { state: { token: response.data.token } });
    } catch (error) {
      // Handle error from the API
      console.error(error);
      setLoginError(true);
    } */
  };

  return (
    <div className="my-20 mt-40">
      <h1 className="text-[40px] font-bold">Bejelentkezés</h1>
      <form className="flex flex-col gap-4 mt-6" onSubmit={handleSubmit}>
        <input
          className="lessdark p-3"
          placeholder="Email..."
          onChange={(e) =>
            setBelepesAdatok({ ...belepesAdatok, username: e.target.value })
          }
        ></input>
        <input
          className="lessdark p-3"
          placeholder="Jelszó..."
          type="password"
          onChange={(e) =>
            setBelepesAdatok({ ...belepesAdatok, password: e.target.value })
          }
        ></input>
        <Button text="Belépés"></Button>
        {loginError && (
          <p className="text-red-500 font-medium">
            Hibás felhasználónév vagy jelszó
          </p>
        )}
      </form>
    </div>
  );
}

export default Login;
