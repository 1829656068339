import React from "react";
import info from "../../../assets/attention.svg";

function Announcement({ data }) {
  console.log(data);
  return (
    data &&
    data?.announcement?.title && (
      <div className="w-full mt-20 px-5 sm:px-12 lg:px-5 max-w-[860px]">
        <div className="w-full bg-[#181818] relative border flex flex-col border-[#2e2e2e] rounded-[8px] p-5 px-5">
          <div className="absolute -top-[10px] rounded-full -right-[10px] w-[42px] h-[42px] bg-black/70 flex items-center justify-center">
            <img
              src={info}
              className="w-[30px] h-[30px] select-none"
              alt="info"
            ></img>
          </div>

          <h1 className="text-center font-bold text-[20px]">
            Tisztelt Ügyfeleink!
          </h1>
          <h2 className="text-center mt-4 font-bold text-[18px]">
            {data.announcement.title}
          </h2>
          <p className="max-w-[600px] mt-3 text-center gry self-center">
            {data.announcement.text.split("\n").map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </p>
        </div>
      </div>
    )
  );
}

export default Announcement;
